import React, { useEffect } from "react"
import { withStyles } from "@material-ui/core"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"

import Reveal from "reveal.js"
import { useGatsbyImagePath, useFluidUrl } from "../images"

import { ThemeProvider } from "@material-ui/styles"
import theme from "./../../presentation-theme"
import Typography from "@material-ui/core/Typography"
import { Clock } from "../svg/clock"
import { AstraloadLogo } from "../svg/astraload-logo"
import { PresentationAstraloadLogo } from "../svg/presentation-astraload-logo"
import {
  PresentationSvg1,
  PresentationSvg2,
  PresentationSvg3,
  PresentationSvg4,
  PresentationSvg5,
  PresentationSvg6,
  PresentationSvg7,
  SvgAstraloadCompany,
  SvgArrowRight, PresentationSvg8,
} from "../svg/presentation-svg"



let presentation

function AstraloadFrontendPresentation(props) {
  const { classes, prWidth, width } = props
  const figmaLogo = useFluidUrl("figmaLogo")
  const storybookLogo = useFluidUrl("storybookLogo")
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    presentation = new Reveal({
      embedded: true,
      width: prWidth,
      progress: false,
      controls: false,
      overview: false,
      height: 900
    })
    presentation.initialize().then(()=>{
      //presentation.slide(2)
    })
  }, [])

  function onNextButtonClick() {
    if (presentation) {
      presentation.next()
    }
  }

  function renderNextButton(isAnimate = false) {
    const animateClass = isAnimate ? "fragment" : ""
    return (
      <div className={classes.footerButton}>
        <div className={classes.nextButton + ` ${animateClass}`} onClick={onNextButtonClick}>
          NEXT
        </div>
      </div>
    )
  }

  function renderHelpSlide() {
    if (isWidthDown('sm', width)) {
      return (
        <div
          className={classes.sectionContent}
          style={{ alignItems: "flex-end" }}
        >
          <SvgAstraloadCompany className={classes.phaseImgTop + ` ${classes.phaseImgTopMobile}`} />
          <div className={classes.swipeBlock}>
            <Typography variant="h1" className={classes.swipeBlockTitle}>
              Swipe Right
            </Typography>
            <Typography variant="body1" className={classes.swipeBlockText}>
              Horizontal view preferred
            </Typography>
            <SvgArrowRight className={classes.arrowRight} />
          </div>
        </div>
      )
    }

    return (
      <div className={classes.sectionContent}>
        <Typography
          variant="h1"
          className={classes.introduceTitle}
          style={{maxWidth: "1000px"}}
        >
          Click <div className={classes.nextButtonExplain} onClick={onNextButtonClick}>NEXT</div> to read the program
          details or you can use right arrow
        </Typography>
        <PresentationSvg8
          className={classes.astraloadSvgImage}
        />
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <div className="reveal">
          <div className="slides">
            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}
              className={classes.section}
            >
              {renderHelpSlide()}
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}

              className={classes.section}
            >
              <div className={classes.sectionContentFromTop}>
                <div className={classes.introduceSlide}>
                  <AstraloadLogo className={classes.introduceLogo}/>
                  <Typography
                    variant="h1"
                    className={classes.introduceTitle}
                  >
                    Astraload frontend program –
                    Design System, Storybook and Testing
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.introduceText}
                  >
                    A vetted team of design, development and facillitation professionals who will scale your frontend development with state-of-the-art tooling, robust processes and methodical coaching by executing a 12-week supercharged enhancement program
                  </Typography>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#8C40FF"
              />
              <div className={classes.sectionContent}>
                <div className={classes.firstSlideTextContainer}>
                  <div
                    className={classes.textBlock + ` ${classes.textContainer}`}
                  >
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3}>
                        Two frontend developers,
                        a&nbsp;designer and
                        a&nbsp;facilitator
                      </Typography>
                    </div>
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3}>
                        In course
                        of 12&nbsp;weeks
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={classes.textBlock + ` ${classes.textContainer}`}
                  >
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3}>
                        Based on a program engineered with love by Astraload team
                      </Typography>
                    </div>
                    <div className={classes.textItem}>
                      <Typography variant="h3" className={classes.titleH3}>
                        Create designs, develop frontend, introduce best practices, train your team
                      </Typography>
                    </div>
                  </div>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationPhasesBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#8C40FF"
              />
              <div className={classes.sectionContentFromTop}>
                <div className={classes.phasesContainer}>
                  <div className={classes.phasesMenuContainer}>
                    <div className={classes.phasesTitle}>Phases</div>
                    <div className={classes.phasesMenu}>
                      <div
                        className="fragment fade-out"
                        data-fragment-index={0}
                      >
                        Auditing and planning
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={0}
                      >
                        Founding a design system
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={1}
                      >
                        Setting up a component library in Storybook
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={2}
                      >
                        Establishing design handoff processes
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={3}
                      >
                        Introducing UI implementation review and screenshot testing
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={4}
                      >
                        Designing responsive user interfaces
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={5}
                      >
                        Implementing UI business logic using domain driven development
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={6}
                      >
                        Unit-testing frontend business logic
                      </div>
                      <div
                        className="fragment highlight-current-red"
                        data-fragment-index={7}
                      >
                        Setting up API sanity checking
                      </div>
                    </div>
                  </div>
                  <div className={classes.phasesDescriptionContainer}>
                    <div className={classes.phasesDescription}>
                      <div className="r-stack">
                        <div
                          className="fragment fade-out"
                          data-fragment-index={0}
                        >
                          <PresentationSvg1
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 Week
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Team training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Efficient communication set up between Astraload and your team
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            List of UI components and their variants
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={0}
                        >
                          <img
                            src={figmaLogo.src}
                            className={classes.phaseImg}
                            width="127"
                            alt=""
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1–2 Weeks
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Team training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Design team knowing how to build design system
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Design system foundation – colors, typography, spacers, components subset – implemented in Figma
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={1}
                        >
                          <img
                            src={storybookLogo.src}
                            className={classes.phaseImg}
                            alt=""
                            width="187"
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1–2 Weeks
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Team training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Development team knowing how to use Storybook for maintaining a component library
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            A component library set up in Storybook
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={2}
                        >
                          <PresentationSvg2
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 Week
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Design team will study how to use CSS Grid, Flexbox and Components definition of done in design discussion, implementation and handoff
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Components designs in Figma
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={3}
                        >
                          <PresentationSvg3
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 Week
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Development team will learn how to use screenshot testing and how to produce pixel-perfect UI
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Complex UI components will be built in Storybook, implemented with pixel-perfect quality and covered with thorough screenshot testing
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={4}
                        >
                          <PresentationSvg4
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1-2 Weeks
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Design team will study how to build complex interfaces with Atomic Design and UX patterns
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Pages and views designs implemented in Figma
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={5}
                        >
                          <PresentationSvg5
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 2 Weeks
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Development team learnt how to build hierarchies of components using Atomic design, components interfaces and Bounded Contexts
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            UI business logic implemented in Storybook
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={6}
                        >
                          <PresentationSvg6
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 Week
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Development team will study efficient and joyful techniques of frontend business logic unit-testing
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Frontend business logic covered with unit-tests
                          </Typography>
                        </div>
                        <div
                          className="fragment fade-in-then-out"
                          data-fragment-index={7}
                        >
                          <PresentationSvg7
                            className={classes.phaseImg}
                          />

                          <div className={classes.phasesDuration}>
                            <Clock className={classes.clockIcon}/> 1 Week
                          </div>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Training result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Development team will study how to build a safety belt around backend API
                          </Typography>

                          <Typography variant="body1" className={classes.phasesSubtitle}>
                            Engineering result:
                          </Typography>

                          <Typography variant="h3" className={classes.titleH3}>
                            Frontend business logic covered with backend API checks
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "effortsStepsBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#FF2C9C"
              />
              <div className={classes.sectionContent}>
                <div>
                  <Typography variant="h3" className={classes.titleH3White}>
                    Each phase consists of the following activities:
                  </Typography>

                  <div className={classes.stepsList}>
                    <div
                      className={classes.stepItem + " fragment fade-out"}
                      data-fragment-index={0}
                    >
                      Planning
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={0}
                    >
                      Technical activities
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={2}
                    >
                      Training
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={3}
                    >
                      Practicing
                    </div>
                    <div
                      className={
                        classes.stepItem + " fragment highlight-current-red"
                      }
                      data-fragment-index={4}
                    >
                      Reporting
                    </div>
                  </div>

                  <div className="r-stack">
                    <div style={{width: "100%"}}>
                      <Typography
                        variant="h1"
                        className={classes.title + " fragment fade-out"}
                        data-fragment-index={0}
                        style={{maxWidth: "720px"}}
                      >
                        We tell what we’re going
                        to do and set up a plan
                        for the phase
                      </Typography>
                    </div>

                    <Typography
                      variant="h1"
                      className={classes.title + " fragment fade-in-then-out"}
                      data-fragment-index={0}
                    >
                      Tech team sets up technologies, processes and best practices. It also does regular design and development work
                    </Typography>

                    <div
                      className={
                        classes.section2EqualContainer +
                        " fragment fade-in-then-out"
                      }
                      data-fragment-index={2}
                    >
                      <Typography variant="h1" className={classes.title}>
                        Facilitator guides{" "}
                        <span className={classes.highlightRed}>your team</span>{" "}
                        through effort-related training
                      </Typography>
                      <Typography variant="h1" className={classes.title}>
                        If you don’t have available frontend team, we can rent
                        one
                      </Typography>
                    </div>
                    <Typography
                      variant="h1"
                      className={classes.title + " fragment fade-in-then-out"}
                      data-fragment-index={3}
                    >
                      Tech guys work closely with your team to practice and institutionalize new processes and standards
                    </Typography>
                    <Typography
                      variant="h1"
                      className={classes.title + " fragment fade-in-then-out"}
                      data-fragment-index={4}
                    >
                      Facilitator presents phase results to stakeholders
                    </Typography>
                  </div>
                </div>
                {renderNextButton()}
              </div>
              <div className={classes.copyright}>© {currentYear} Astraload, Inc.</div>
            </section>

            <section
              data-background-image={useGatsbyImagePath(
                "presentationContactsBg",
                "fluid"
              )}
              className={classes.section}
            >
              <PresentationAstraloadLogo
                className={classes.slideLogo}
                fill="#FFFFFF"
              />
              <div className={classes.sectionContent}>
                <div className={classes.contacts}>
                  <Typography
                    variant="h1"
                    className={classes.contactsTitle}
                  >
                    Astraload is a small team of seasoned professionals,
                    all our services are highly exclusive — <a href="https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform" target="_blank">
                       book the team now
                    </a>, while it’s available
                  </Typography>

                  <div className={classes.contactsFooter}>
                    <Typography variant="body1" className={classes.contactsInfo}>
                      Have any questions?
                    </Typography>
                    <div className={classes.contactsLinks}>
                      <a href="https://blog.astraloadhq.com/" target="_blank">Read our blog</a>
                      <a href="https://t.me/joinchat/DQIDQU7Naz7doknz7u8meQ" target="_blank">Join Telegram group</a>
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform" target="_blank">Get a quote</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.copyright + ` ${classes.copyrightWhite}`}>© {currentYear} Astraload, Inc.</div>
            </section>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

const styles = theme => {
  const flexContainer = {
    display: "flex",
    justifyContent: "space-between",
  }

  return {
    container: {
      height: "100%",
      position: "relative",

      "& *": {
        fontFamily: "Inter, sans-serif",
      },
    },
    iframe: {
      border: "none",
    },
    section: {
      height: "100%",
    },
    sectionContent: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    sectionContentFromTop: {
      height: "100%",
      display: "flex",
      paddingTop: 24
    },
    footerButton: {
      position: "absolute",
      bottom: 24,
      left: "50%",
      transform: "translate(-50%, 0)"
    },
    textBlock: {
      display: "flex",

      "& $titleH3": {
        margin: 0,
        maxWidth: 360
      },

      "&:not(:last-child)": {
        marginBottom: 48
      }
    },
    firstSlideTextContainer: {
      width: "100%",
      maxWidth: 952,
      margin: "0 auto"
    },
    phasesContainer: {
      display: "flex",
      textAlign: "left",
      width: "100%"
    },
    phasesTitle: {
      fontWeight: 700,
      color: "#373340",
      fontSize: 24,
      lineHeight: "32px",
      marginBottom: 40,
    },
    phasesDuration: {
      fontWeight: 700,
      color: "#373340",
      fontSize: 24,
      lineHeight: "32px",
      marginBottom: 32,
      position: "relative",
      display: "flex",
      alignItems: "center"
    },
    clockIcon: {
      marginRight: 16,
      display: "flex",
      alignItems: "center"
    },
    phasesSubtitle: {
      fontSize: 24,
      fontWeight: 400,
      color: "#75038A",
      marginBottom: 16
    },
    phasesMenuContainer: {
      maxWidth: 424,
      width: "100%",
      marginRight: 48
    },
    phasesDescriptionContainer: {
      width: "100%",

      "& .r-stack > *": {
        margin: "0 !important"
      }
    },
    phasesMenu: {
     "& > *" : {
       fontWeight: 500,
       fontSize: 18,
       lineHeight: "24px",
       transition: "none !important",

       "&:not(:last-child)": {
         marginBottom: 24
       },

       "&.highlight-current-red.current-fragment": {
         fontSize: 32,
         lineHeight: "40px",
         color: "#75038A !important",
       },
       "&.fade-out:not(.visible)": {
         fontSize: 32,
         lineHeight: "40px",
         color: "#75038A !important",
       },
       "&.fragment.visible.fade-out": {
         opacity: "1 !important",
         visibility: "visible !important",
       },
     }
    },
    phaseImg: {
      position: "absolute",
      userSelect: "none",
      right: 0,
      bottom: 10,
      zIndex: -1,

      [theme.breakpoints.down("sm")]: {
        transform: "scale(0.7)",
        transformOrigin: "right center"
      },
    },
    section2EqualContainer: {
      ...flexContainer,

      "& > *": {
        width: "48%",
      },
    },
    title: {
      "&.highlight-red.visible": {
        color: "#F7D4F6 !important",
      },
    },
    titleH3: {
      color: "#75038A",
      margin: 0,
      minWidth: 360,
      paddingRight: 20,

      "&:not(:last-child)": {
        marginBottom: 40
      }
    },
    titleH3White: {
      color: theme.palette.text.white,
      maxWidth: "660px",
    },
    textContainer: {
      ...flexContainer,

      "& > *": {
        width: "42%",
      },
    },
    stepsList: {
      display: "flex",
      margin: "48px 0",

      "& + $title": {
        maxWidth: 730,
      },
    },
    stepItem: {
      padding: "6px 12px",
      background: theme.palette.text.white,
      fontWeight: 500,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      fontSize: 16,
      margin: 0,
      borderRadius: 12,

      "&:not(:last-child)": {
        marginRight: 24,
      },

      "&.highlight-current-red.current-fragment": {
        backgroundColor: "#75038A",
        color: "#ffffff !important",
      },
      "&.fade-out:not(.visible)": {
        backgroundColor: "#75038A",
        color: "#ffffff !important",
      },
      "&.fragment.visible.fade-out": {
        opacity: "1 !important",
        visibility: "visible !important",
      },
    },
    highlightRed: {
      color: "#FF2C9C",
    },
    contacts: {
      textAlign: "left",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "92px 48px",
      boxSizing: "border-box"
    },
    contactsTitle: {
      margin: "0 0 32px 0",
      color: theme.palette.text.white,

      "& a": {
        fontSize: 60,
        lineHeight: "72px",
        fontWeight: 700,
        textDecoration: "underline",
        "&:hover": {
          opacity: 0.8
        },
      }
    },
    topNote: {
      marginBottom: 90,
      color: theme.palette.text.white,
      maxWidth: 760,
      fontSize: 24,
      fontWeight: 400
    },
    contactsSubtitle: {
      margin: "0 0 24px 0",
    },
    contactsInfo: {
      color: theme.palette.text.white,
      fontWeight: "normal",

      "& a": {
        textDecoration: "underline",
        fontSize: 24,
        lineHeight: "40px",
        fontWeight: "normal",
      },
    },
    button: {
      padding: "8px 16px",
      fontSize: 16,
      borderRadius: 12,
      textAlign: "center",
      marginTop: 48,
      color: theme.palette.text.white,
      cursor: "pointer",
      textDecoration: "none",

      "& span": {
        display: "inline-block",
      },
    },
    nextButton: {
      padding: "8px 16px",
      fontSize: 16,
      borderRadius: 12,
      textAlign: "center",
      marginTop: 24,
      color: "#75038A",
      border: "1px solid #75038A",
      cursor: "pointer",
      transition: "all 0.3s ease-out",
      userSelect: "none",

      "&:hover": {
        backgroundColor: "#75038A",
        color: "#fff",
      },
    },
    scheduleButton: {
      display: "inline-block",
      textDecoration: "underline",
      fontSize: 24,
      lineHeight: "40px",
      fontWeight: "normal",
      cursor: "pointer",
    },
    contactsFooter: {
      display: "flex",
      justifyContent: "space-between"
    },
    contactsLinks: {
      "& a": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 400,
        borderBottom: "1px solid #ffffff",

        "&:hover": {
          opacity: 0.8
        },

        "&:not(:last-child)": {
          marginRight: 56
        }
      }
    },
    copyright: {
      position: "absolute",
      bottom: 32,
      left: 0,
      color: "#373340",
      fontWeight: 500,
      zIndex: 1,
    },
    copyrightWhite: {
      color: "#ffffff"
    },
    introduceSlide: {
      maxWidth: 856
    },
    introduceLogo: {
      marginBottom: 70,

      "& svg": {
        display: "block"
      }
    },
    introduceTitle: {
      marginBottom: 48
    },
    introduceText: {
      color: "#373340",
      fontWeight: 400
    },
    slideLogo: {
      position: "absolute",
      right: 0,
      top: 0
    },
    nextButtonExplain: {
      display: "inline-block",
      padding: "8px 16px",
      fontSize: 64,
      fontWeight: 700,

      border: "3px solid #FF2C9C",
      borderRadius: "20px",
      cursor: "pointer",

      textAlign: "center",
      marginTop: 24,
      color: "#FF2C9C",
      transition: "all 0.3s ease-out",
      userSelect: "none",

      "&:hover": {
        backgroundColor: "#FF2C9C",
        color: "#fff",
      },

      "&:active": {
        backgroundColor: "#E20279",
        color: "#fff",
      },
    },
    phaseImgTop: {
      position: "absolute",
      userSelect: "none",
      left: 0,
      top: 48,
      zIndex: -1,
    },
    phaseImgTopMobile: {
      width: 360,
      height: 160,

      "& svg": {
        width: "100%",
        height: "100%"
      }
    },
    swipeBlock: {
      fontSize: "32px",
      lineHeight: "40px",
      width: "100%",
      marginBottom: "48px",
      textAlign: "left",
      position: "relative"
    },
    arrowRight: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(0, -50%)",
      width: 100,
      height: 100,

      "& svg": {
        width: "100%",
        height: "100%"
      }
    },
    swipeBlockTitle: {
      fontSize: 100,
      lineHeight: "140px",
      marginBottom: 10
    },
    swipeBlockText: {
      color: "#75038A",
      fontWeight: 400,
      fontSize: 60
    },
    astraloadSvgImage: {
      userSelect: "none",
      position: "absolute",
      bottom: 0,
      right: 0,
      left: 0
    }
  }
}

// eslint-disable-next-line
AstraloadFrontendPresentation = withWidth()(withStyles(styles)(AstraloadFrontendPresentation))

export { AstraloadFrontendPresentation }
