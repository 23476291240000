import React, { useEffect } from "react"
import { AstraloadFrontendPresentation } from "../components/moleculs/astraload-frontend-presentation"
import useAnalytics from "../hooks/useAnalytics"

const IndexPage = ({ location }) => {
  const { trackPage } = useAnalytics()

  useEffect(() => {
    const { pathname } = location

    trackPage(pathname)
  }, [])

  return (
    <div style={{ height: "100vh" }}>
      <AstraloadFrontendPresentation prWidth={1200} />
    </div>
  )
}

export default IndexPage
